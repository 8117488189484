import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../CssFiles/createcount.css";
import { auth } from "../firebase.js";
import { getFirestore, collection, doc, setDoc } from "firebase/firestore";
import AgreementForm from "./cgu";

const apiUrl = process.env.REACT_APP_API_URL;

async function registerUser(credentials) {
  const {
    email,
    password,
    username,
    userlastname,
    profilePicture,
    cellPhone,
    addressPostal,
  } = credentials;

  const userCredential = await auth.createUserWithEmailAndPassword(email, password);
  await userCredential.user.sendEmailVerification();

  const app = auth.app;
  const firestore = getFirestore(app);
  const userDocRef = doc(collection(firestore, "users"), userCredential.user.uid);

  await setDoc(userDocRef, {
    email: userCredential.user.email,
    username: username,
    userlastname: userlastname,
    profilePicture: profilePicture,
    cellPhone: cellPhone,
    addressPostal: addressPostal,
  });

  console.log("Making request to:", `${apiUrl}/create-stripe-customer`);

  const response = await fetch(`${apiUrl}/create-stripe-customer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email: email }),
  });

  if (!response.ok) {
    const errorResponse = await response.text();
    throw new Error(errorResponse || 'Unknown error occurred');
  }

  const data = await response.json();
  console.log("Stripe customer ID:", data.customerId);

  return userCredential.user;
}

function CreateCount() {
  const [username, setUserName] = useState("");
  const [userlastname, setUserlastName] = useState("");
  const [password, setPassword] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cellPhone, setCellPhone] = useState("");
  const [addressPostal, /*setAddressPostal*/] = useState("");
  const [error, setError] = useState("");
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const navigate = useNavigate();

  const [profilePicture, /*setProfilePicture*/] = useState(null);
  const [profilePictureDataURL, setProfilePictureDataURL] = useState(null);

  useEffect(() => {
    if (profilePicture) {
      const reader = new FileReader();
      reader.onloadend = () => setProfilePictureDataURL(reader.result);
      reader.readAsDataURL(profilePicture);
    }
  }, [profilePicture]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const isValidPassword = validatePassword(password);
      if (!isValidPassword) {
        setError("The password must contain at least 8 characters with one uppercase letter, one lowercase letter, and one number.");
        return;
      }
  
      if (password !== reenterPassword) {
        setError("Passwords do not match.");
        return;
      }
  
      await registerUser({
        username,
        userlastname,
        email,
        password,
        profilePicture: profilePictureDataURL,
        cellPhone,
        addressPostal,
      });
  
      setIsAccountCreated(true);
      alert('Account created successfully! Please check your email for verification.');
      navigate("/"); // Rediriger vers l'accueil'
    } catch (error) {
      setError(error.message);
      console.error("Error submitting the form:", error);
    }
  };

  const handlePasswordFocus = () => {
    setIsPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setIsPasswordFocused(false);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleUsernameChange = (e) => setUserName(e.target.value);

  const handleUserlastnameChange = (e) => setUserlastName(e.target.value);

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleReenterPasswordChange = (e) => {
    const reenterPass = e.target.value;
    setReenterPassword(reenterPass);

    if (password && reenterPass && password !== reenterPass) {
      setError("Passwords do not match.");
    } else {
      setError("");
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    setCellPhone(value);
  };

  return (
    <div className="Createcount">
      <h1 className="Title">
        <a onClick={() => navigate("/")} href="/">
          <img className="mage" src="../logo.png" alt="Logo" />
        </a>
      </h1>
      <p className="headinge">Account Information</p>
      <form className="forme_main" onSubmit={handleSubmit}>
        <div className="inputContainere">
          <label className="labelStyle">First name</label>
          <input
            id="username"
            required={true}
            className="inputField"
            type="text"
            value={username}
            onChange={handleUsernameChange}
            onInvalid={(e) =>
              e.target.setCustomValidity("Please enter your Firstname")
            }
            onInput={(e) => e.target.setCustomValidity("")}
          />
        </div>
        <div className="inputContainere">
          <label className="labelStyle">Last name</label>
          <input
            id="lastname"
            required={true}
            className="inputField"
            type="text"
            value={userlastname}
            onChange={handleUserlastnameChange}
            onInvalid={(e) =>
              e.target.setCustomValidity("Please enter your Lastname")
            }
            onInput={(e) => e.target.setCustomValidity("")}
          />
        </div>
        <div className="inputContainere">
          <label className="labelStyle">Email</label>
          <input
            id="email"
            required={true}
            className="inputField"
            type="email"
            value={email}
            onChange={handleEmailChange}
            onInvalid={(e) =>
              e.target.setCustomValidity("Please enter your Email")
            }
            onInput={(e) => e.target.setCustomValidity("")}
          />
        </div>
        <div className="inputContainere">
          <label className="labelStyle">Phone number</label>
          <input
            id="cellPhone"
            required={true}
            className="inputField"
            type="tel"
            value={cellPhone}
            inputMode="numeric"
            pattern="[0-9]*"
            onChange={handlePhoneNumberChange}
          />
        </div>
        <div className="inputContainere">
          <label className="labelStyle">Password</label>
          <input
            id="password"
            required={true}
            className="inputField"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            onFocus={handlePasswordFocus}
            onBlur={handlePasswordBlur}
          />
        </div>
        <div className="inputContainere">
          <label className="labelStyle">Re-enter Password</label>
          <input
            id="reenterPassword"
            required={true}
            className="inputField"
            type="password"
            value={reenterPassword}
            onChange={handleReenterPasswordChange}
          />
        </div>
        {isPasswordFocused && (
          <p className="passwordRequirements">
            <span className="blue-text">
              Password must contain at least 8 characters with one uppercase
              letter, one lowercase letter, and one number.
            </span>
          </p>
        )}
        {error && (
          <p className="error">
            {error === "The email address is already in use by another account." && (
              <span className="email-error">
                This email is already used by another account.
              </span>
            )}
            {error !== "The email address is already in use by another account." && error}
          </p>
        )}
        {isAccountCreated && (
          <p className="success-message">Account created successfully!</p>
        )}
        <AgreementForm />
        <div className="buttonContainer">
          <button id="button" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreateCount;