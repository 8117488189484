import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./logo";
import "../CssFiles/homepage.css";
import "../CssFiles/App.css";
import Carouselle from "./secondPage";
import Footer from "./footer";
import Carousel from "./Carousel";
import SlickCarousel from "./SlickCarousel";
import Component from "./component";

function HomePage({ articles }) {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    // Redirige l'utilisateur vers la page "/register" lorsque le bouton "Login" est cliqué
    navigate("/register");
  };

  const handleGetStartedClick = () => {
    // Redirige l'utilisateur vers le lien spécifié lorsque le bouton "Get Started" est cliqué
    // window.location.href = 'https://4690qg0v00i.typeform.com/to/CwuJNzZj';
    navigate("/payment");
  };

  const handleArticleClick = () => {
    navigate("/addarticle");
  };

  return (
    <div className="home-page-container">
      <Logo />
      <video className="back" autoPlay loop muted playsInline>
        <source src="/Videos/tweny.mp4" type="video/mp4" />
      </video>
      <button className="started" onClick={handleGetStartedClick}>
        {" "}
        Get Started{" "}
      </button>
      <button className="co" onClick={handleLoginClick}>
        {" "}
        Login{" "}
      </button>

      {/* <Carousel articles={articles} /> */}
      {/* <SlickCarousel /> */}
      <Carouselle />

      <Component />
      {/* <div className="button-container">
        <button onClick={handleArticleClick} className="addbutton">
          {" "}
          Add article{" "}
        </button>
      </div> */}

      <Footer />
    </div>
  );
}

export default HomePage;
